import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

class NotFoundPage extends React.Component {
  
  render() {

    return (
      <Layout>

        <div style={{textAlign: `center`, height: `600px`}}>
          <h1>404 NOT FOUND</h1>
          <p>It looks like you've found a page that doesn't exist. Head back <a href="https://www.joshmorony.com/blog">home</a>.
          </p>
        </div>

      </Layout>
    )
  }
}

export default NotFoundPage